<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <dx-data-grid
        ref="refEmployeeDetailGrid"
        :data-source="locationInfoList"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :height="gridHeight"
        :focused-row-enabled="true"
        key-expr="장소코드"
        :focused-row-key.sync="focusedRowKey"
        @editor-preparing="onEditorPreparing"
        @row-updated="onRowUpdated"
        @row-updating="onUpdating"
        @row-inserted="onRowInserted"
        @row-inserting="onRowInserting"
        @row-removed="onRowRemoved"
        @row-removing="onRowRemoving"
        @init-new-row="onInitNewRow"
      >
        <DxColumnFixing :enabled="true" />
        <DxEditing
          :allow-deleting="true"
          :allow-updating="true"
          :allow-adding="true"
          :use-icons="true"
          mode="row"
        />
        <DxColumn
          :allow-editing="false"
          caption="사이트"
          data-field="사이트"
          :min-width="60"
        />
        <DxColumn
          :allow-editing="true"
          caption="장소코드"
          data-field="장소코드"
          :min-width="100"
        >
          <dx-required-rule message="항목을 입력하세요" />
        </DxColumn>
        <DxColumn
          :allow-editing="true"
          caption="장소명"
          data-field="장소명"
          :min-width="100"
        >
          <dx-required-rule message="항목을 입력하세요" />
        </DxColumn>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
          show-scrollbar="always"
          :use-native="true"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, { DxEditing, DxScrolling, DxColumn, DxPaging, DxSorting, DxColumnFixing } from 'devextreme-vue/data-grid'
import {
  DxRequiredRule
} from 'devextreme-vue/validator'
// import AppLib from '../../share/AppLib'
// import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'

export default {
  name: 'EmptView',
  components: {
    Loading,
    DxDataGrid,
    DxColumnFixing,
    DxEditing,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxRequiredRule
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      locationInfoList: null,
      focusedRowKey: null,
      isEditing: true,
      gridHeight: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.refreshLocation()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 90)
  },
  updated () {
  },
  methods: {
    onEditorPreparing (e) {
    },
    onInitNewRow (e) {
      console.log(e)
      e.data.사이트 = this.locationInfoList[0].사이트
      e.data.저장장소 = this.locationInfoList[0].저장장소
    },
    onRowUpdated (row) {
      console.log(row.data)
      this.isLoading = true
      this.$_sp.runUpdateSqlProc('장소정보', ['장소코드'], null, [row.data])
        .then((data) => {
          this.isLoading = false
        })
    },
    onUpdating (row) {
      if (row.newData.장소코드 !== undefined) {
        this.$snotify.info('장소코드는 변경할 수 없습니다.')
        row.cancel = true
      }
    },
    onRowInserted (row) {
      console.log(row.data)
      this.isLoading = true
      this.$_sp.runInsertSqlProc('장소정보', null, [row.data])
        .then((data) => {
          this.isLoading = false
        })
    },
    onRowInserting (row) {
      if (this.$_.findIndex(this.locationInfoList, { 장소코드: row.data.장소코드 }) >= 0) {
        this.$snotify.info('사용중인 장소코드 입니다.')
        row.cancel = true
      }
    },
    onRowRemoved (row) {
      this.isLoading = true
      this.$_sp.runDeleteSqlProc('장소정보', [{ 장소코드: row.data.장소코드 }])
        .then((data) => {
          this.isLoading = false
        })
    },
    onRowRemoving (row) {

    },
    refreshLocation () {
      this.isLoading = true
      this.$_sp.runNoEncodeFindSqlProc('장소정보', null)
        .then((data) => {
          this.isLoading = false
          this.locationInfoList = this.$_sp.MakeModel(data)
          console.log(this.locationInfoList)
        })
    }
  }
}
</script>

<style lang="scss">

</style>
